import { useState, useEffect } from 'react'
import './App.css'
import Titre from './components/titre'
import Navigation from './components/navigation'
import Navbutton from './components/navbutton'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import Home from './components/home'
import Presentation from './components/presentation'
import Competences from './components/competences'
import Contact from './components/contact'
import { Helmet, HelmetProvider } from 'react-helmet-async'
import Mentionslegales from './components/mentionslegales'
import Polconfidentialite from './components/polconfidentialite'
function App() {
  const [currentPage, setCurrentPage] = useState('')

  useEffect(() => {
    const navLinks = document.getElementsByClassName('nav-item')
    Object.keys(navLinks).forEach((element) => {
      if (
        currentPage + 'link' ===
        document.getElementsByClassName('nav-item')[element].id
      ) {
        document.getElementsByClassName('nav-item')[element].style.color =
          'grey'
        document.getElementsByClassName('nav-item')[element].style.cursor =
          'default'
      } else {
        document.getElementsByClassName('nav-item')[element].style.color =
          'white'
        document.getElementsByClassName('nav-item')[element].style.cursor =
          'pointer'
      }

      if (window.innerWidth < 601) {
        document.getElementById('page').style.left = '-100%'
      } else {
        document.getElementById('page').style.left = '-85%'
      }
      if (currentPage !== '') {
        setTimeout(function () {
          console.log(
            JSON.stringify(
              document.getElementById('page').style.transitionDelay
            )
          )
          document.getElementById('page').style.left = '0'
        }, 2000)
      } else {
        document.getElementById('page').style.left = '0'
      }
    })
  }, [currentPage])

  return (
    <HelmetProvider>
      <Helmet>
        <title>Marie Lalandes | Avocat - Droit des Sociétés - Itxassou</title>
      </Helmet>
      <Mentionslegales />
      <Polconfidentialite />
      <Router>
        <Titre currentPage={currentPage} />

        <Navbutton />
        <Navigation currentPage={currentPage} setCurrentPage={setCurrentPage} />
        <div className='page' id='page'>
          <Switch>
            <Route exact path='/'>
              <Home setCurrentPage={setCurrentPage} />
            </Route>
            <Route path='/presentation'>
              <Presentation />
            </Route>
            <Route path='/competences'>
              <Competences />
            </Route>
            <Route path='/contact'>
              <Contact />
            </Route>
          </Switch>
        </div>
      </Router>
    </HelmetProvider>
  )
}

export default App
