import React from 'react'
import { Helmet } from 'react-helmet-async'
import '../assets/css/competences.css'

function competences() {
  return (
    <>
      <Helmet>
        <title>
          Droit des Sociétés et droit des affaires | Marie Lalandes | Avocat -
          Itxassou
        </title>
      </Helmet>
      <div className='page-title'>
        <h3>Nos Compétences</h3>
      </div>
      <div className='page-content'>
        <h4 className='page-sub-title'>
          Activités en Droit des Sociétés et Droit des Affaires
        </h4>
        <ul className='competences-list'>
          <li className='competences-list-item'>
            Conseils aux Dirigeants{' '}
            <span className='muted'>
              (distribution dividendes, rémunération, apport à une holding,
              protections, investissements)
            </span>
            .
          </li>
          <li className='competences-list-item'>
            Acquisition ou cession de Titres / Fonds de Commerce.
          </li>
          <li className='competences-list-item'>Pactes d’Associés.</li>
          <li className='competences-list-item'>
            Transformation de Sociétés, opérations de restructuration{' '}
            <span className='muted'>
              (Fusions, Apport partiel d’Actifs, Transmission Universelle de
              Patrimoine, Apport de titres)
            </span>
            .
          </li>
          <li className='competences-list-item'>
            Opérations portant sur le capital social.
          </li>
          <li className='competences-list-item'>
            Création de Société, avec notamment un accompagnement dans le choix
            de la forme sociale{' '}
            <span className='muted'>
              (Société Anonyme SA, Société par actions simplifiée SAS, Société
              par actions simplifiée à Associé unique SASU, Société à
              responsabilité limitée SARL, Entreprise unipersonnelle à
              responsabilité limitée EURL, Entreprise Individuelle à l’impôt sur
              le revenu ou à l’impôt sur les Sociétés, Société civile
              immobilière SCI à l’impôt sur le revenu ou à l’impôt sur les
              Sociétés…)
            </span>{' '}
            et du régime fiscal <span className='muted'>(IR, IS, TVA)</span>.
          </li>
          <li className='competences-list-item'>
            Accompagnement départ en retraite des Dirigeants.
          </li>
          <li className='competences-list-item'>
            Accompagnement dans la transmission du Patrimoine des Dirigeants.
          </li>
          <li className='competences-list-item'>
            Mise en place de Management package.
          </li>
          <li className='competences-list-item'>Contrats de partenariats.</li>
          <li className='competences-list-item'>Baux commerciaux.</li>
          <li className='competences-list-item'>
            Secrétariat juridique{' '}
            <span className='muted'>
              (approbation de comptes, changement de direction, modification de
              l’objet social, transfert de siège social…)
            </span>
            .
          </li>
        </ul>
        <p className='highlighted'>
          Selon vos besoins, nous collaborons étroitement avec divers
          professionnels et notamment des Avocats spécialisés, des
          Experts-comptables ou des Notaires.
        </p>
      </div>
    </>
  )
}

export default competences
